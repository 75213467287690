import Link from "next/link";
import PropTypes from "prop-types";
import React from "react";

const Eyebrow = ({ eyebrowTitle, isBlackBackground, isExternal, url }) => {
  let element = null;

  const spanElement = (
    <span
      style={{
        backgroundColor: isBlackBackground ? "#000" : "#fff",
        color: isBlackBackground ? "#fff" : "#000",
        padding: "5px 10px",
      }}
    >
      {eyebrowTitle}
    </span>
  );

  if (isExternal) {
    element = (
      <a href={url} rel="noopener noreferrer" target="_blank">
        {spanElement}
      </a>
    );
  } else {
    element = <Link href={url}>{spanElement}</Link>;
  }

  return (
    <div
      style={{
        margin: "30px 0",
      }}
    >
      {element ? element : spanElement}
    </div>
  );
};

Eyebrow.defaultProp = {
  isExternal: false,
  url: null,
};

Eyebrow.propTypes = {
  eyebrowTitle: PropTypes.string.isRequired,
  isBlackBackground: PropTypes.bool.isRequired,
  isExternal: PropTypes.bool,
  url: PropTypes.oneOf([PropTypes.string, PropTypes.any]),
};

export default Eyebrow;
